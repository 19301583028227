import React, { Component } from 'react';
import { IndexPageStyle, IndexPageContent } from './style';
import TopPage from './components/topPage';
import LeftPage from './components/leftPage';
import CenterPage from './components/centerPage';
import RightPage from './components/rightPage';
import leftPageData from './mock/leftPageData'
import rightPageData from './mock/rightPageData'
import centerPageData from './mock/centerPageData'

class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <IndexPageStyle>
        <TopPage />
        <IndexPageContent>
          {/* 左侧内容 */}
          <LeftPage {...leftPageData['GET /api/leftPageData']} />
          {/* 中间内容 */}
          <CenterPage className='center-page' {...centerPageData['GET /api/centerPageData']} />
          {/* 右侧内容 */}
          <RightPage {...rightPageData['GET /api/rightPageData']} />
        </IndexPageContent>
      </IndexPageStyle>
    );
  }
}

export default IndexPage
