
import './public-path';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import './utils/flexible';


export async function bootstrap() {
  console.log('[react15] react app bootstraped');
}
// export function bootstrap(cb) {
//   console.log('[react15] react app bootstraped');
//   if (typeof cb === 'function') {
//     cb();
//   }
// }

export async function mount(props = {}) {
  console.log('[react15] props from main framework', props);
  const { container } = props;
  const selector = '#react-big-screen-root'
  ReactDOM.render(
    <App />,
    (container || document).querySelector(selector)
  );

  import('./dynamic.css').then(() => {
    console.log('[react15] dynamic style load');
  });
}

export async function unmount(props) {
  const { container } = props;
  ReactDOM.unmountComponentAtNode(
    container ? container.querySelector('#react15Root') : document.getElementById('react15Root'),
  );
}

if (!window.__POWERED_BY_QIANKUN__) {
  // bootstrap().then(mount);
  // bootstrap().then(mount);
  import('./utils/flexible').then(() => {
    bootstrap().then(mount);

  })
}
